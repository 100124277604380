<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <div class="header">
            <h1>Комплексная модернизация</h1>
            <div>
              <v-btn
                  v-if="$user.role === 'ROLE_VNII' || $user.role === 'ROLE_MINTRUD' || $user.role === 'ROLE_ADMIN'"
                  @click="$router.push('/standard/vnii')"
                  outlined
              >
                просмотр отчетов по срезам
              </v-btn>
              <v-btn
                  v-if="$user.role === 'ROLE_VNII' || $user.role === 'ROLE_MINTRUD' || $user.role === 'ROLE_ADMIN'"
                  @click="$router.push('/standard/requests')"
                  outlined
              >
                просмотр всех заявок
              </v-btn>
            </div>
          </div>

          <template v-if="$user.role !== 'ROLE_CZN_TERRITORIAL'">
            <div>
              <h4>Уровень мониторинга:</h4>
              <v-radio-group v-model="monitoringLevel" row @change="loadData()">
                <v-radio label="Регионы" value="region"></v-radio>
                <v-radio label="ЦЗН" value="czn"></v-radio>
              </v-radio-group>
            </div>
            <department-info v-if="monitoringLevel === 'czn'" @depObj="department = $event; loadData()"></department-info>
            <template v-else-if="monitoringLevel === 'region'">
              <span v-if="$user.role === 'ROLE_REGIONAL_AGENCY' || $user.role === 'ROLE_CZN_MANAGER'">
                {{ userRegion.name }}
              </span>
              <v-autocomplete v-else-if="$user.role === 'ROLE_VNII' || $user.role === 'ROLE_MINTRUD' || $user.role === 'ROLE_ADMIN'"
                              style="width: 500px; margin-top: 20px; margin-bottom: 76px"
                              label="Регион"
                              :items="regionList"
                              item-text="name"
                              return-object
                              v-model="userRegion"
                              @input="loadData()"
              ></v-autocomplete>
            </template>
          </template>
          <template v-else>
            <span>{{ $user.department.name }}</span>
          </template>

          <template v-if="!loading">
            <div class="hdr">Активные отчётные формы</div>
            <div class="list">
              <table>
                <tbody>
                <tr v-for="config in configs" :key="config.id" v-show="!config.filled">
                  <td>
                    {{ config.content.title }}
                  </td>
                  <td class="text-right">
                    <v-btn @click="createReport(config.content.context)" outlined>
                      Заполнить отчетную форму
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-if="configs.filter(config => !config.filled).length === 0" style="height: 48px; margin-top: 20px">
                Нет активных отчетных форм к заполнению
              </div>
            </div>

            <div class="hdr">Заполненные отчётные формы</div>
            <div class="list">
              <table>
                <thead>
                <tr>
                  <th>Наименование</th>
                  <th>Дата создания</th>
                  <th>Дата отправки</th>
                  <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="report in reports" :key="report.id">
                  <td>
                    <router-link :to="'/standard/report/' + report.id">{{ findNameByContext(report.context) }}</router-link>
                  </td>
                  <td>{{ report.createdDateTime | dateFormat }}</td>
                  <td v-if="report.sendDateTime">{{ report.sendDateTime | dateFormat }}</td>
                  <td v-else>—</td>
                  <td>{{ reportStatuses[report.status] }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="reports.length === 0" style="height: 48px; margin-top: 20px">
                Не найдены заполненные отчётные формы по комплексной модернизации
              </div>
            </div>
          </template>

          <template v-if="checkRoles(requestRoles) && !requests.length && !loading" class="mt-5">
            <p>Регион не участвует в комплексной модернизации</p>
            <p>Для участия в комплексной модернизации заполните заявку.</p>
            <v-btn @click="createRequest" outlined>
              Заполнить заявку
            </v-btn>
          </template>

          <template v-if="checkRoles(requestRoles) && requests.length && !loading">
            <div class="hdr">Заявки</div>
            <div class="list">
              <table>
                <thead>
                <tr>
                  <th>Наименование</th>
                  <th>Дата создания</th>
                  <th>Дата отправки</th>
                  <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="request in requests" :key="'request_' + request.id">
                  <td>
                    <router-link :to="'/standard/request/' + request.id">
                      Заявка на участие в комплексной модернизации
                    </router-link>
                  </td>
                  <td>{{ request.createdDateTime | dateFormat }}</td>
                  <td>{{ request.sendDateTime | dateFormat }}</td>
                  <td>{{ requestStatuses[request.status] }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>
        <div v-if="errorText != null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<script>
import departmentInfo from "@/components/elements/DepartmentInfo";
import api from "@/modules/api";
import {
  getFormsByDomainAndCznIdAndContext,
  getFormsByDomainAndRegionCode,
  getFormsByDomainAndRegionCodeAndContext,
  saveForm
} from "@/modules/api.forms";
import {reportStatuses, requestStatuses} from "@/modules/ApplicationRepository";
import {getActiveDomainConfigs} from "@/modules/api.configs";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";

export default {
  name: 'StandardComponent',
  components: {
    LoaderDialog,
    departmentInfo
  },
  data: () => ({
    domainReport: 'standard_report',
    domainRequest: 'standard_request',
    requests: [],
    reports: [],
    configs: [],
    loading: false,
    requestRoles: ['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY'],
    monitoringLevel: "region",
    department: {name: null},
    regionList: [],
    userRegion: {},
    requestStatuses: requestStatuses,
    reportStatuses: reportStatuses,
    apiLoaded: false,
    errorText: null,
  }),
  methods: {
    async loadData() {
      this.loading = true
      if (this.monitoringLevel === 'region') {
        this.department = {name: null}
      }

      await this.loadReportConfigs()
      await this.processConfigs()
      await this.loadReports()
      await this.loadRequests()
      this.loading = false
    },
    async loadReportConfigs() {
      this.configs = (await getActiveDomainConfigs(this.domainReport)).payload
    },
    async processConfigs() {
        this.configs.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1)

        if (this.monitoringLevel === 'region') {
          this.configs = this.configs.filter(config => config.content.regions.includes(this.userRegion.code))
        }
        if (this.monitoringLevel === 'czn') {
          this.configs = this.configs.filter(config => config.content.czns.includes(this.department.id))
        }
    },
    async loadReports() {
      this.reports = []
      for (const config of this.configs) {
        let reports = [];

        if (this.monitoringLevel === 'region') {
          reports = (await getFormsByDomainAndRegionCodeAndContext(
              this.domainReport,
              this.userRegion.code,
              config.content.context
          )).payload;
        }

        if (this.monitoringLevel === 'czn') {
          reports = (await getFormsByDomainAndCznIdAndContext(
              this.domainReport,
              this.department.id,
              config.content.context
          )).payload;
        }

        config.filled = reports.length
        this.reports = this.reports.concat(reports.filter(newReport =>
            !this.reports.some(existingReport => existingReport.id === newReport.id)
        ))
      }
    },
    async loadRequests() {
      this.requests = (await getFormsByDomainAndRegionCode(this.domainRequest, this.userRegion.code)).payload
    },
    findNameByContext(context) {
        return this.configs.find(config => config.content.context === context)?.content.title
    },
    async createRequest() {
      const requestForm = {
        domain: {
          code: this.domainRequest,
        },
        regionCode: this.userRegion.code,
        status: "DRAFT",
        sendDateTime: new Date(),
      }
      const request = await saveForm(requestForm)
      if (request) {
        await this.$router.push(`/standard/request/${request.payload.id}`)
      }
    },
    async createReport(context) {
      const reportForm = {
        domain: {
          code: this.domainReport,
        },
        context: context,
        regionCode: this.userRegion?.code,
        cznId: this.department?.id,
        status: "DRAFT",
        sendDateTime: new Date(),
      }
      const report = await saveForm(reportForm)
      if (report) {
        await this.$router.push(`/standard/report/${report.payload.id}`)
      }
    },

    async getRegionList() {
      let req = await api.get("/getRegionsList");
      if (req.ok) {
        this.regionList = req.payload;
        this.regionList.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        console.log('get regionList error');
      }
    },
    checkRoles(userRoles) {
      for (let role of userRoles) {
        if (this.$user.subroles.includes(role)) {
          return true;
        }
      }
      return false;
    },
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    if (this.$user.role === 'ROLE_ADMIN' || this.$user.role === 'ROLE_MINTRUD' || this.$user.role === 'ROLE_VNII') {
      await this.getRegionList();
    }
    this.apiLoaded = true;
    if (this.$user.role === 'ROLE_CZN_MANAGER' ||
        this.$user.role === 'ROLE_CZN_TERRITORIAL' ||
        this.$user.role === 'ROLE_REGIONAL_AGENCY') {
      this.userRegion = this.$user.region;
    } else {
      this.userRegion = this.regionList[0];
    }
    if (this.$user.role === 'ROLE_CZN_TERRITORIAL') {
      this.department = this.$user.department;
      this.monitoringLevel = 'czn';
    }
    await this.loadData();
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
.wrap > h1 > div {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1C1C1F;
  }

  .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
  }

  .v-input--selection-controls .v-radio > .v-label {
    font-size: 15px;
  }

  > div {
    width: 450px;

    &:first-child {
      margin-right: 77px;
    }
  }
}

.list {
  table {
    border-collapse: collapse;

    th {
      border-bottom: 1px solid #E8E8E9;
    }
  }
}
</style>
