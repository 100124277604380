<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div>
        <div v-if="apiLoaded" class="wrap">
          <a @click="$router.go(-1)" class="back-link">
            <v-icon class="icon-item icon-chevron-left"></v-icon>
            Назад
          </a>
          <h1>Заявка на участие в комплексной модернизации
            <v-btn outlined @click="exportXlsx()">
              экспорт
            </v-btn></h1>
          <h2>{{ getRegionNameByCode(request.regionCode) }}</h2>
          <span>{{ requestStatuses[request.status] }}</span>

          <div class="list passport">
            <div v-for="(section, sectionIndex) in config.sections" :key="'section_' + sectionIndex"
                 class="list-item-wrap">
              <v-expansion-panels>
                <v-expansion-panel :key="'vep' + sectionIndex">
                  <v-expansion-panel-header expand-icon="">
                    <div class="list-item">
                      <a class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24"
                               width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24"
                               width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>{{ sectionIndex + 1 }}. {{ section.name }}</span>
                      </a>
                      <v-btn class="button-edit"
                             v-if="checkRoles(requestRoles) && (request.status === 'DRAFT' || request.status === 'EDITED')"
                             @click.stop="docsDialog(section)">Редактировать
                      </v-btn>
                      <v-progress-circular
                          :rotate="-90"
                          :size="50"
                          :width="6"
                          :value="fillPercent(sectionIndex)"
                          color="#0033A0">
                        {{ fillPercent(sectionIndex) }}
                      </v-progress-circular>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <table>
                      <thead>
                      <tr>
                        <th>Параметр</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(indicator, indicatorIndex) in section.indicators" :key="indicator.id">
                        <td>
                          <div>
                            {{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}. {{ indicator.name }}
                          </div>
                        </td>
                        <td>
                          <div class="request-params">
                            <div class="request-param request-param-attach">
                              <template v-for="attachment in findAttachments(indicator.id)">
                                <div :key="attachment.id + 'fileIndex'" style="display: block">
                                  <div>
                                    <a @click="downloadAttachment(attachment)">{{ truncateStr(attachment.name, 30) }}</a>
                                  </div>
                                </div>
                              </template>
                              <template v-if="!findAttachments(indicator.id).length">
                                <div style="display: block">
                                  <div>
                                    —
                                  </div>
                                </div>
                              </template>
                            </div>
                            <div class="request-param request-param-szn">
                              {{ findValue(indicator.id, "VALUE")?.value || '—' }}
                            </div>
                            <div class="request-param request-param-czn">
                              {{ findValue(indicator.id, "COMMENT")?.value || '—' }}
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>

          <v-dialog
              v-model="dialog"
              max-width="800px"
              persistent>
            <template v-slot:activator="{ on }">
              <div v-on="on">
              </div>
            </template>
            <StandardRequestDialog
                @close="dialog=false; dialogKey++; initialize();"
                :key="dialogKey"
                :section="section"
                :request-id="request.id"
            />
          </v-dialog>

          <div class="buttons">
            <v-btn class="button-cancel" @click="$router.push('/standard/')">
              Назад
            </v-btn>
            <v-btn
                class="button-request"
                @click="sendRequest()"
                v-if="checkRoles(requestRoles) && (request.status === 'DRAFT' || request.status === 'EDITED')"
            >
              Отправить заявку
            </v-btn>

            <v-btn
                class="button-cancel"
                @click="rejectRequest()"
                v-if="($user.role === 'ROLE_VNII' || $user.role === 'ROLE_MINTRUD') && request.status === 'SENT'"
            >
              Отправить на корректировку
            </v-btn>

            <v-btn
                class="button-request"
                @click="approveRequest()"
                v-if="($user.role === 'ROLE_VNII' || $user.role === 'ROLE_MINTRUD') && request.status === 'SENT'"
            >
              Одобрить
            </v-btn>
          </div>
        </div>
        <div v-if="errorText != null" class="error">
          Ошибка: {{ errorText }}
        </div>
      </div>
    </transition>
    <LoaderDialog :show="loading" msg="Загрузка"></LoaderDialog>
  </div>
</template>

<script>

import {requestStatuses} from "@/modules/ApplicationRepository";
import {getFormById, saveForm} from "@/modules/api.forms";
import {getActiveDomainConfigs} from "@/modules/api.configs";
import StandardRequestDialog from "@/components/standard_new/StandardRequestDialog.vue";
import {getIndicatorsByIndicatorFormId} from "@/modules/api.indicators";
import {downloadFile} from "@/modules/api.files";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";

export default {
  name: 'StandardRequest',
  components: {
    LoaderDialog,
    StandardRequestDialog,
  },
  data: () => ({
    request: null,
    requestValues: [],
    loading: false,
    requestRoles: ['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY'],
    domain: 'standard_request',
    requestStatuses: requestStatuses,
    dialog: false,
    dialogKey: 0,
    section: 0,
    config: null,
    apiLoaded: false,
    errorText: null,
  }),
  methods: {
    async initialize() {
      this.apiLoaded = false
      this.loading = true

      const requestId = this.$route.params.id
      this.request = (await getFormById(requestId)).payload
      this.requestValues = (await getIndicatorsByIndicatorFormId(requestId)).payload
      this.config = (await getActiveDomainConfigs(this.domain)).payload[0].content

      this.loading = false
      this.apiLoaded = true
    },

    findValue(indicatorId, type) {
      return this.requestValues.find(e => e.indicatorId === indicatorId && e.type === type)
    },
    findAttachments(indicatorId) {
      const attachmentsJson = this.findValue(indicatorId, 'FILES')
      return attachmentsJson ? JSON.parse(attachmentsJson.value) : []
    },

    valuesPresentCount(sectionIndex) {
      let section = this.config.sections[sectionIndex]
      let indicatorIds = section ? section.indicators.map(indicator => indicator.id) : []

      let uniqueIndicatorIds = new Set();
      this.requestValues.forEach(value => {
        if (indicatorIds.includes(value.indicatorId)) {
          uniqueIndicatorIds.add(value.indicatorId);
        }
      });

      return uniqueIndicatorIds.size;
    },

    valuesRequiredCount(sectionIndex){
      let section = this.config.sections[sectionIndex]
      return section.indicators.length
    },

    fillPercent(sectionIndex){
      return Math.round(this.valuesPresentCount(sectionIndex) / this.valuesRequiredCount(sectionIndex) * 100)
    },

    docsDialog(section) {
      this.dialog = true
      this.section = section
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    getRegionNameByCode(code) {
      return this.$cznDepartmentsByRegionList.find(r => r.code === code)?.name
    },

    async downloadAttachment(attachment) {
      this.loaderDialog = true
      await downloadFile(attachment.id, attachment.name)
      this.loaderDialog = false
    },

    async sendRequest() {
      this.request.status = 'SENT'
      this.request.sendDateTime = new Date()

      await saveForm(this.request)
      await this.$router.push('/standard/')
    },

    async rejectRequest() {
      this.request.status = 'EDITED'
      this.request.sendDateTime = new Date()

      await saveForm(this.request)
      await this.$router.push('/standard/')
    },

    async approveRequest() {
      this.request.status = 'APPROVED'
      this.request.sendDateTime = new Date()

      await saveForm(this.request)
      await this.$router.push('/standard/')
    },

    async exportXlsx() {
      const rows = []
      for (let section of this.config.sections) {
        for (let indicator of section.indicators) {
          let value = this.findValue(indicator.id, 'VALUE')
          let control = this.findValue(indicator.id, 'COMMENT')
          let files = this.findValue(indicator.id, 'FILES')
          let fileNames = files ? JSON.parse(files.value)
              .map(e => `${e.name} (${this.$backendUrl}/files/${e.id})`)
              .join(', ') : ''

          rows.push([
            {cellDataType: "STRING", value: section.name},
            {cellDataType: "STRING", value: indicator.name},
            {cellDataType: "STRING", value: fileNames},
            {cellDataType: "STRING", value: value?.value},
            {cellDataType: "STRING", value: control?.value}
          ])
        }
      }
      let query = {
        headerCells: [
          {
            caption: "Раздел",
            width: 5000,
          },
          {
            caption: "Показатель",
            width: 7000,
          },
          {
            caption: "Файлы",
            width: 7000,
          },
          {
            caption: "Комментарий",
            width: 7000,
          },
          {
            caption: "Контроль",
            width: 7000,
          }
        ],
        rows
      }
      await this.$downloadApi("Заявка_комплексная_модернизация.xlsx", "/export/xlsx", query, true);
    },
    checkRoles(userRoles) {
      for (let role of userRoles) {
        if (this.$user.subroles.includes(role)) {
          return true;
        }
      }
      return false;
    },
  },

  async beforeMount() {
    await this.initialize()
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>


