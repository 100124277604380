<template>
  <div v-cloak>
    <v-card class="docs request-docs" v-if="apiLoaded">
      <v-card-title>
        <div class="text-h5">Документы, прилагаемые к заявке<br>для отбора на первом этапе</div>
      </v-card-title>
      <table>
        <thead>
        <tr>
          <th>Параметр</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(indicator, indicatorIndex) in section.indicators"
            :key="indicator.id">
          <td>
            {{ section.id }}.{{ indicatorIndex + 1 }}. {{ indicator.name }}
          </td>
          <td>
            <div class="standard-docs-params">
              <div>
                <div>
                <span class="error">
                  {{ indicator.validationError }}
                </span>
                  <div class="">
                    <template v-for="attachment in findAttachments(indicator.id)">
                      <div style="display: block" :key="attachment.id + 'fileIndex'">
                        <div class="docs-attach">
                          <a @click="downloadAttachment(attachment)">{{ truncateStr(attachment.name, 30) }}</a>
                          <a
                              v-if="$user.role==='ROLE_REGIONAL_AGENCY'"
                              style="cursor:pointer; font-weight: 600; margin-left: 5px"
                              @click="deleteAttachment(indicator.id, attachment)"
                          >x</a>
                        </div>
                      </div>
                    </template>

                    <input type="file" multiple :ref="'file_upload_' + indicator.id" style="display:none"
                           @change="addAttachments(indicator.id, $event.target.files)">
                    <a
                        v-if="$user.role === 'ROLE_REGIONAL_AGENCY'"
                        @click="$refs['file_upload_' + indicator.id][0].click()" class="upload-link"><i>Добавить файлы</i></a>
                  </div>
                  <v-textarea
                      :disabled="$user.role !== 'ROLE_REGIONAL_AGENCY'"
                      placeholder="Комментарий"
                      :value="findValue(indicator.id, 'VALUE')?.value"
                      @input="value => setValue(indicator.id, 'VALUE', value)"
                  ></v-textarea>
                </div>
              </div>
              <div>
                <v-textarea
                    :disabled="$user.role !== 'ROLE_VNII' && $user.role !== 'ROLE_MINTRUD' && $user.role !== 'ROLE_ADMIN'"
                    placeholder="Комментарий куратора региона"
                    :value="findValue(indicator.id, 'COMMENT')?.value"
                    @input="value => setValue(indicator.id, 'COMMENT', value)"
                ></v-textarea>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <v-card-actions>
        <v-btn
            class="btn btn-outline"
            text
            @click="$emit('close')"
        >
          Отмена
        </v-btn>
        <v-btn
            class="btn btn-primary"
            @click="submit()"
        >
          Сохранить изменения
        </v-btn>
      </v-card-actions>

    </v-card>
    <LoaderDialog :show="loading" msg="Загрузка"></LoaderDialog>
  </div>
</template>

<script>
import LoaderDialog from "@/components/elements/LoaderDialog";
import {getFormById} from "@/modules/api.forms";
import {getIndicatorsByIndicatorFormId, saveIndicators} from "@/modules/api.indicators";
import {downloadFile, uploadFile} from "@/modules/api.files";

export default {
  name: 'StandardRequestDialog',
  components: {LoaderDialog},
  props: {
    requestId: {
      type: String
    },
    section: {
      type: Object,
    },
  },
  data: () => ({
    apiLoaded: false,
    loading: false,
    request: null,
    requestValues: [],
  }),
  methods: {
    findValue(indicatorId, type) {
      return this.requestValues.find(e => e.indicatorId === indicatorId && e.type === type)
    },
    findAttachments(indicatorId) {
      const attachmentsJson = this.findValue(indicatorId, 'FILES')
      return attachmentsJson?.value ? JSON.parse(attachmentsJson.value) : []
    },
    setValue(indicatorId, type, val) {
      let value = this.findValue(indicatorId, type)
      if (!value) {
        value = this.createEmptyValue(indicatorId, type)
        this.requestValues.push(value)
      }

      value.value = val
      this.$forceUpdate()
    },
    createEmptyValue(indicatorId, type) {
      return {
        indicatorId: indicatorId,
        indicatorFormId: this.requestId,
        value: null,
        type: type,
        cznId: this.request.cznId,
        regionCode: this.request.regionCode,
        context: this.request.context,
        period: this.request.period,
      }
    },

    async addAttachments(indicatorId, attachments){
      this.loading = true
      const attachedFiles = this.findAttachments(indicatorId)

      for (const attachment of attachments) {
        const fileInfo = await uploadFile(attachment);
        attachedFiles.push({ id: fileInfo.id, name: fileInfo.name });
      }

      this.setValue(indicatorId, "FILES", JSON.stringify(attachedFiles))
      this.loading = false
    },

    deleteAttachment(indicatorId, attachment){
      let attachedFiles = this.findAttachments(indicatorId)
      attachedFiles.splice(attachedFiles.findIndex(e => e.id === attachment.id), 1)
      this.setValue(indicatorId, "FILES", JSON.stringify(attachedFiles))
    },

    async downloadAttachment(attachment) {
      this.loaderDialog = true
      await downloadFile(attachment.id, attachment.name)
      this.loaderDialog = false
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    async submit() {
      if (!this.validateValues()) {
        return
      }

      await saveIndicators(this.requestValues)
      this.$emit('close')
    },

    validateValues() {
      let valid = true
      for (let indicator of this.section.indicators) {
        valid = this.validateValue(indicator) && valid
      }
      this.$forceUpdate()
      return valid
    },

    validateValue(indicator) {
      const attachments = this.findAttachments(indicator.id)
      if (attachments == null || attachments.length === 0) {
        indicator.validationError = 'Прикрепите хотя бы один файл'
        return false
      }

      const value = this.findValue(indicator.id, "VALUE")
      if (value?.value == null || value?.value.length === 0){
        indicator.validationError = 'Дайте пояснение в поле комментарий'
        return false
      }
      indicator.validationError = null
      return true
    }
  },

  async beforeMount() {
    this.loading = true

    this.request = (await getFormById(this.requestId)).payload
    this.requestValues = (await getIndicatorsByIndicatorFormId(this.requestId)).payload
    if (!this.request) {
      alert('Не найдена заявка')
      return
    }

    this.loading = false
    this.apiLoaded = true
  }
}
</script>


