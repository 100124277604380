<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="(apiLoaded || errorText) && ($user.role === 'ROLE_VNII' || $user.role === 'ROLE_MINTRUD' || $user.role === 'ROLE_ADMIN')">
        <div v-if="apiLoaded" class="wrap">
          <a @click="$router.go(-1)" class="back-link">
            <v-icon class="icon-item icon-chevron-left"></v-icon>
            Назад
          </a>
          <h1 v-if="configId == null">
            Комплексная модернизация по срезам
            <div class="download-menu">
              <v-dialog
                  v-model="dialog"
                  max-width="600px"
                  persistent>
                <template v-slot:activator="{ on }">
                  <div class="download" v-on="on">
                    <v-btn outlined>
                      экспорт
                    </v-btn>
                  </div>
                </template>
                <StandardExportDialog @close="dialog = false; dialogKey++" :key="dialogKey"/>
              </v-dialog>
            </div>
            <div>
              <h4>Тип среза:</h4>
              <v-radio-group v-model="format" row @change="loadData">
                <v-radio label="По регионам или ЦЗН" value="byRegionsOrCzn"></v-radio>
                <v-radio label="По мониторингам" value="byReports"></v-radio>
              </v-radio-group>
            </div>
            <template v-if="format === 'byRegionsOrCzn'">
              <div>
                <h4>Уровень мониторинга:</h4>
                <v-radio-group v-model="level" row @change="loadData">
                  <v-radio label="Регионы" value="region"></v-radio>
                  <v-radio label="ЦЗН" value="czn"></v-radio>
                </v-radio-group>
              </div>
              <department-info v-if="level === 'czn'" @depObj="department = $event; loadData()"></department-info>
              <v-autocomplete
                  v-else
                  style="width: 500px; margin-top: 20px; margin-bottom: 76px"
                  dense
                  label="Регион"
                  :items="regionList"
                  item-text="name"
                  return-object
                  v-model="userRegion"
                  @input="loadData"
              ></v-autocomplete>
            </template>
            <template v-if="format === 'byReports'">
              <v-autocomplete
                  style="width: 500px; margin-top: 20px; margin-bottom: 76px"
                  dense
                  label="Мониторинг"
                  :items="sourceConfigs"
                  item-text="content.title"
                  return-object
                  v-model="config"
                  @input="loadData"
              ></v-autocomplete>
            </template>
          </h1>
          <h1 v-else>
            Комплексная модернизация по срезам
            <div>
              <h4>
                {{ config.content.title }} [id{{ config.id }}]
              </h4>
            </div>
          </h1>

          <div class="hdr">Заполненные отчётные формы</div>
          <div class="list">
            <template>
              <table>
                <thead>
                <tr>
                  <th>{{ firstColumnLabel() }}</th>
                  <th>Дата создания</th>
                  <th>Дата отправки</th>
                  <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="report in reports" :key="report.id">
                  <td>
                    <router-link :to="'/standard/report/' + report.id">
                      {{ firstColumnValue(report) }}
                    </router-link>
                  </td>
                  <td>{{ report.createdDateTime | dateFormat }}</td>
                  <td v-if="report.sendDateTime">{{ report.sendDateTime | dateFormat }}</td>
                  <td v-else>—</td>
                  <td>{{ reportStatuses[report.status] }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="reports.length === 0" style="height: 48px; margin-top: 20px">
                Не найдены заполненные отчётные формы по комплексной модернизации
              </div>
            </template>
          </div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<script>
import StandardExportDialog from "@/components/standard_new/StandardExportDialog";
import departmentInfo from "@/components/elements/DepartmentInfo";
import api from "@/modules/api";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";
import {
  getFormsByDomainAndContext,
  getFormsByDomainAndCznIdAndContext,
  getFormsByDomainAndRegionCodeAndContext
} from "@/modules/api.forms";
import {getDomainConfigs} from "@/modules/api.configs";

export default {
  name: 'StandardVniiComponent',
  components: {
    LoaderDialog,
    departmentInfo,
    StandardExportDialog,
  },
  data: () => ({
    dialog: false,
    domain: 'standard_report',
    reports: [],
    configs: [],
    sourceConfigs: [],
    configId: null,
    config: null,
    dialogKey: 0,
    format: "byRegionsOrCzn",
    level: "region",
    department: null,
    regionList: [],
    userRegion: {},
    reportStatuses: {
      DRAFT: "Черновик",
      SENT: "Отправлено"
    },
    apiLoaded: false,
    errorText: null,
    loading: null,
  }),
  methods: {
    async loadConfigs() {
      this.sourceConfigs = (await getDomainConfigs(this.domain)).payload
    },
    async loadData() {
      this.loading = true
      await this.processConfigs()
      await this.loadReports()
      this.loading = false
    },
    async processConfigs() {
      this.configs = this.sourceConfigs
      this.configs.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1)

      if (this.format === "byReports") {
        this.configs = this.configs.filter(config => config.id === this.config.id)
      }

      if (this.format === "byRegionsOrCzn") {
        if (this.level === 'region') {
          this.configs = this.configs.filter(config => config.content.regions.includes(this.userRegion.code))
        }
        if (this.level === 'czn') {
          this.configs = this.configs.filter(config => config.content.czns.includes(this.department?.id))
        }
      }
    },
    async loadReports() {
      this.reports = []
      for (const config of this.configs) {
        let reports = []

        if (this.format === 'byReports') {
          reports = (await getFormsByDomainAndContext(
              this.domain,
              config.content.context
          )).payload

          if (config.content.type === 'czn') {
            reports.sort((a, b) => (
                this.getCznNameByCznId(a.cznId) >
                this.getCznNameByCznId(b.cznId)) ? 1 : -1)
          } else if (config.content.type === 'regions') {
            reports.sort((a, b) => (
                this.getRegionNameByCode(a.regionCode) >
                this.getRegionNameByCode(b.regionCode)) ? 1 : -1)
          }
        }

        if (this.format === 'byRegionsOrCzn') {
          if (this.level === 'region') {
            reports = (await getFormsByDomainAndRegionCodeAndContext(
                this.domain,
                this.userRegion.code,
                config.content.context
            )).payload
          }

          if (this.level === 'czn') {
            reports = (await getFormsByDomainAndCznIdAndContext(
                this.domain,
                this.department.id,
                config.content.context
            )).payload
          }
        }

        this.reports = this.reports.concat(reports.filter(newReport =>
            !this.reports.some(existingReport => existingReport.id === newReport.id)
        ))
      }
    },
    async getRegionList() {
      let req = await api.get("/getRegionsList");
      if (req.ok) {
        this.regionList = req.payload;
        this.regionList.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        console.log('get regionList error');
      }
    },
    firstColumnLabel() {
      if (this.format === 'byRegionsOrCzn') {
        return 'Наименование'
      }
      if (this.config.content.type === 'region') {
        return 'Регионы'
      }
      if (this.config.content.type === 'czn') {
        return 'ЦЗН'
      }
    },
    firstColumnValue(report) {
      if (this.format === 'byRegionsOrCzn') {
        return this.sourceConfigs.find(config => config.content.context === report.context).content.title
      }
      if (this.config.content.type === 'region') {
        return this.getRegionNameByCode(report.regionCode)
      }
      if (this.config.content.type === 'czn') {
        return this.getCznNameByCznId(report.cznId)
      }
    },
    getRegionNameByCode(regionCode) {
      return this.$cznDepartmentsByRegionList.find(r => r.code === regionCode)?.name
    },
    getCznNameByCznId(cznId) {
      for (const region of this.$cznDepartmentsByRegionList) {
        const department = region.departments.find(c => c.id === cznId);
        if (department) {
          return department.name;
        }
      }
      return cznId;
    },
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    await this.loadConfigs()

    this.configId = this.$route.params.configId
    if (this.configId != null) {
      this.format = "byReports"
      this.config = this.sourceConfigs.find(config => config.id === this.configId)
    } else {
      await this.getRegionList()
      this.userRegion = this.regionList[0]
      this.config = this.sourceConfigs[0]
    }

    await this.loadData()
    this.apiLoaded = true
  }
}
</script>

<style scoped lang="scss">
.wrap > h1 > div:not(.download-menu) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1C1C1F;
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
  .v-input--selection-controls .v-radio > .v-label {
    font-size: 15px;
  }
  > div {
    &:first-child {
      margin-right: 77px;
    }
  }
}

.list {
  table {
    border-collapse: collapse;
    th {
      border-bottom: 1px solid #E8E8E9;
      &:last-child {
        width: 100px;
        text-align: center;
      }
    }
    td {
      &:last-child {
        width: 100px;
        text-align: center;
      }
    }
  }
}

.download-menu {
  top: 0;
  margin-top: 0;
}

</style>

<style>
.v-input--radio-group__input {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
</style>

<style lang="scss">
@import "../../styles/main.scss";
</style>


